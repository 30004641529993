import React, { useState } from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import ComeGrowWithUs from "../components/ComeGrowWithUs";

import ICON_1 from "../img/pages/careers/icons/relentless.svg";
import ICON_2 from "../img/pages/careers/icons/passionate.svg";

import Fade from "react-reveal/Fade";

function Careers() {
  return (
    <Layout
      //   location={this.props.location}
      //   title={siteTitle}
      HeaderExtension={
        <div className="header_extension hero_style careers">
          <div className="container cp-content">
            <h1>Not your typical company</h1>

            <p>And we like it that way</p>
          </div>
        </div>
      }
    >
      <SEO
        title="Careers"
        keywords={["Beauty career, Upper Canada Soap career opportunities"]}
        description="Looking for a beauty career? Learn more about Upper Canada Soap career opportunities."
      />

      <div className="page-content pages-careers">
        <div className="section-content">
          <div className="container">
            <div className="columns">
              <div className="column is-half">
                <Fade bottom duration={1000} delay={125} distance={"25%"}>
                  <div className="cp-card">
                    <img src={ICON_1} alt="Relentless innovators" />

                    <h5>Relentless innovators</h5>

                    <p>
                      We foster a constantly collaborative culture that
                      encourages honest communication and champions creative
                      thinking from everyone.
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="column is-half">
                <Fade bottom duration={1000} delay={250} distance={"25%"}>
                  <div className="cp-card">
                    <img src={ICON_2} alt="Passionate go-getters" />

                    <h5>Passionate go-getters</h5>

                    <p>
                      We’re dedicated to our employees’ individual career
                      growth. Work hard and take ownership, and we’ll empower
                      you in every way possible.
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>

        <ComeGrowWithUs additionalClassName="cp-beige-light" />
      </div>
    </Layout>
  );
}

export default Careers;
